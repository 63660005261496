import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {WindowService} from '../window.service';
import {UserGroupModel} from './model/user-group.model';

@Injectable({
  providedIn: 'root'
})
export class AgentService {

  private readonly AUTH_SERVICE_HUB_KEY = 'serviceQueryParams';
  private readonly LEGACY_AUTH_SERVICE_HUB_KEY = 'dsm-servicehub:auth';

  constructor(private readonly authService: AuthService,
              private readonly windowService: WindowService) {
  }

  getAbsCustomerId(): string {
    let customerId = this.getValueFromAuthServiceHubFor(this.AUTH_SERVICE_HUB_KEY, 'absCustomerId');
    if (customerId === '') {
      customerId = this.getValueFromAuthServiceHubFor(this.LEGACY_AUTH_SERVICE_HUB_KEY, 'absCustId');
    }
    return customerId;
  }

  getAmisPersonId(): string {
    let amisPersonId = this.getValueFromAuthServiceHubFor(this.AUTH_SERVICE_HUB_KEY, 'amisPersonId');
    if (amisPersonId === '') {
      amisPersonId = this.getValueFromAuthServiceHubFor(this.LEGACY_AUTH_SERVICE_HUB_KEY, 'amisPersonId');
    }
    return amisPersonId;
  }

  getVTNR(): string {
    let vtnr = this.getValueFromAuthServiceHubFor(this.AUTH_SERVICE_HUB_KEY, 'vtnr');
    if (vtnr === '') {
      vtnr = this.getValueFromAuthServiceHubFor(this.LEGACY_AUTH_SERVICE_HUB_KEY, 'vtnr');
    }
    return vtnr.replace(/\//g, '');
  }

  getUserGroup(): UserGroupModel {
    let userGroupString = this.getValueFromAuthServiceHubFor(this.AUTH_SERVICE_HUB_KEY, 'userGroup');
    if (userGroupString === '') {
      userGroupString = this.getValueFromAuthServiceHubFor(this.LEGACY_AUTH_SERVICE_HUB_KEY, 'userGroup');
    }
    return UserGroupModel[userGroupString as keyof typeof UserGroupModel];
  }

  getToken(): string {
    let clientToken = this.getValueFromAuthServiceHubFor(this.AUTH_SERVICE_HUB_KEY, 'clientToken');
    if (clientToken === '') {
      clientToken = this.getValueFromAuthServiceHubFor(this.LEGACY_AUTH_SERVICE_HUB_KEY, 'token');
    }
    return clientToken;
  }

  hasToken(): boolean {
    return this.getToken() !== '';
  }

  hasVTNR(): boolean {
    return this.getVTNR() !== '';
  }

  private getValueFromAuthServiceHubFor(itemKey: string, key: string): string {
    if (this.authService.isAgent()) {
      const authServiceHub = JSON.parse(sessionStorage.getItem(itemKey));
      if (authServiceHub && authServiceHub[key]) {
        return authServiceHub[key];
      }
    }
    return '';
  }
}
