import {Component, Input, OnInit} from '@angular/core';
import {AuthorizedRepresentative} from '../../../core/models/kommdata.model';
import {ConfigurationService} from '../../../core/services/config.service';
import {AgentService} from '../../../core/services/auth/agent.service';

@Component({
  selector: 'dsmkd-komm-data-programm-overview',
  templateUrl: './komm-data-program-overview.component.html',
  styleUrls: ['./komm-data-program-overview.component.scss']
})
export class KommDataProgramOverviewComponent implements OnInit {

  @Input() mazAlreadyActive = false;
  @Input() weweAlreadyActive = false;
  @Input() deweAlreadyActive = false;
  @Input() personName: string;
  @Input() legalPerson: boolean;
  @Input() authorizedRepresentative: AuthorizedRepresentative;
  @Input() showErrorMissingKommData: boolean;
  @Input() hasAddress: boolean;
  @Input() isNovaUser: boolean;

  public allProgramsChecked: boolean;
  public checkedMaz: boolean;
  public checkedWEWE: boolean;
  public checkedPersonalIdentification = false;
  public dEWEFeatureFlagActive = false;

  advancedSettings: boolean = false;

  constructor(private configurationService: ConfigurationService,
              private agentService: AgentService) {
  }

  ngOnInit(): void {
    this.configurationService.getConfig().subscribe(config => {
      this.dEWEFeatureFlagActive = config.deweProcessingFeatureFlag && this.agentService.hasVTNR();
      this.allProgramsChecked = this.selectAllProgramsCheckbox();
      this.checkedMaz = this.checkMaz();
    });
  }

  selectAllProgramsCheckbox(): boolean {
    return (!this.weweAlreadyActive && !this.mazAlreadyActive) || (this.shouldShowDewe() && !this.deweAlreadyActive);
  }

  checkMaz(): boolean {
    let checkMazCheckbox = !this.mazAlreadyActive && this.weweAlreadyActive;
    if (this.shouldShowDewe()) {
      checkMazCheckbox = checkMazCheckbox && this.deweAlreadyActive;
    }
    return checkMazCheckbox;
  }

  toggleAdvancedSettings() {
    this.advancedSettings = !this.advancedSettings;
  }

  noProgramActive(): boolean {
    return !this.mazAlreadyActive && !this.weweAlreadyActive || (!this.deweAlreadyActive && this.shouldShowDewe());
  }

  showIdentNatPerson(): boolean {
    const shouldShowAgentIdent = (this.allProgramsChecked || this.checkedMaz) && !this.legalPerson && this.hasAddress;
    return shouldShowAgentIdent && !this.mazAlreadyActive;
  }

  shouldShowDewe(): boolean {
    return this.dEWEFeatureFlagActive && this.isNovaUser;
  }

  shouldShowWewe(): boolean {
    return this.dEWEFeatureFlagActive;
  }

  isWeweActiveAndShown(): boolean {
    return this.weweAlreadyActive && this.shouldShowWewe();
  }

  isWeweInactiveAndShown(): boolean {
    return !this.weweAlreadyActive && this.shouldShowWewe();
  }

  isDeweActiveAndShown(): boolean {
    return this.deweAlreadyActive && this.shouldShowDewe();
  }

  isDeweInactiveAndShown(): boolean {
    return !this.deweAlreadyActive && this.shouldShowDewe();
  }
}
